import { useState, useRef, useEffect } from 'react';
// @mui
import { Box, List, Badge, Divider, Typography, IconButton, ListItemText, ListItemButton, Stack } from '@mui/material';
// utils
// components
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import Notification from '../../pages/Notification';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import axiosInstance from '../../api/withJWTServer';
import { requestForToken } from '../../firebase';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------
const firebaseConfig = {
  apiKey: 'AIzaSyDOweSDzMKb5qEpu1vO7imzXDXDq1bNjYA',
  authDomain: 'sunn-grahak.firebaseapp.com',
  projectId: 'sunn-grahak',
  storageBucket: 'sunn-grahak.appspot.com',
  messagingSenderId: '864315570501',
  appId: '1:864315570501:web:ac70de93536623110da62c',
  measurementId: 'G-HYZZDZE5LP',
};

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [notification, setNotification] = useState([]);
  const [open, setOpen] = useState(null);
  useEffect(() => {
    axiosInstance
      .get('vendor/notifications')
      .then((res) => {
        const array =
          res.data.data.length > 10
            ? res.data.data.slice(res.data.data.length - 10, res.data.data.length)
            : res.data.data;
        const indexes = Array.from({ length: array.length }, (_, index) => index);

        // Sort the indexes in descending order
        const sortedIndexes = indexes.sort((a, b) => b - a);

        // Map over the sorted indexes and access the original array elements
        const sortedArray = sortedIndexes.map((index) => array[index]);
        setNotification(sortedArray);
      })
      .catch((err) => {
        setNotification([]);
      });
  }, []);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  useEffect(() => {
    requestForToken();
    onMessage(messaging, (payload) => {
      const newNotification = {
        title: payload.notification.title,
        notification_heading: payload.notification.body,
        fcm_noti: 1,
      };

      // Update the notification state with the latest notification
      setNotification((prevNotifications) => {
        const updatedNotifications = [newNotification, ...prevNotifications];
        return updatedNotifications;
      });
    });
  }, []);

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        {notification.length > 0 ? (
          <Badge badgeContent={notification.length >= 10 ? '9+' : notification.length} color="error">
            <Iconify icon="eva:bell-fill" width={20} height={20} />
          </Badge>
        ) : (
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Stack direction={'row'} p={'15px'}>
          <Typography flex={1} variant="subtitle1">
            Notifications
          </Typography>

          {notification.length > 0 && (
            <Link
              onClick={() => {
                handleClose();
              }}
              to={'notifications'}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                sx={{ textDecoration: 'none' }}
                fontWeight={'600'}
                color={'green'}
                fontSize={'14px'}
                textAlign={'right'}
              >
                See All
              </Typography>
            </Link>
          )}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar
          sx={{
            height: {
              xs: `${notification.length > 0 ? 340 : 100}`,
              sm: 'auto',
              lg: `${notification.length > 0 ? '300px' : '60px'}`,
            },
            overflowY: 'auto',
          }}
        >
          <List disablePadding>
            {notification.length > 0 ? (
              notification
                .slice()
                .sort((a, b) => b - a)
                .map((item, key) => (
                    <div key={key}>
                      <ListItemButton
                        sx={{
                          mt: '1px',
                          alignItems: 'flex-start',
                          alignContent: 'flex-start',
                        }}
                      >
                        <NotificationsIcon style={{ color: 'green', fontSize: '20px', marginTop: '5px' }} />
                        <Stack
                          direction={'column'}
                          sx={{ ml: '8px', alignItems: 'flex-start', alignContent: 'flex-start' }}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              color: 'black',
                              textAlign: 'start',
                              fontWeight: '600',
                              fontSize: '16px',
                            }}
                          >
                            {item?.notification_heading}
                          </Typography>

                          <Typography
                            sx={{
                              display: 'flex',
                              textAlign: 'start',
                              fontWeight: '300',
                              fontSize: '14px',
                            }}
                          >
                            {item?.notification_content}
                          </Typography>

                          <Typography
                            sx={{
                              display: 'flex',
                              textAlign: 'start',
                              fontWeight: '500',
                              fontSize: '12px',
                              color: 'gray',
                            }}
                          >
                            {moment(item.createdAt).format('DD/MM/YYYY hh:mm A')}
                          </Typography>
                        </Stack>
                      </ListItemButton>
                      <Divider />
                    </div>
                  ))
            ) : (
              <div>
                <ListItemButton
                  sx={{
                    py: 1.5,
                    px: 2.5,
                    mt: '1px',
                  }}
                >
                  <ListItemText
                    primary={'No New Notification'}
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 0.5,
                          ml: 5,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.disabled',
                        }}
                      />
                    }
                  />
                  <NotificationImportantIcon style={{ color: 'red' }} />
                </ListItemButton>
              </div>
            )}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />
      </MenuPopover>
    </>
  );
}
